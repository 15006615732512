import React, { useState, useEffect } from 'react';
import { FaPlus, FaStar, FaTags, FaNewspaper, FaArrowLeft } from 'react-icons/fa';
import { storageRongve, firestoreRongve } from '../../firebase';
import productApi from '../../utils/productApi';
import { productStyles } from './components/ProductStyles';
import ProductCard from './components/ProductCard';
import ProductForm from './components/ProductForm';
import Axios from 'axios';

export const PRODUCT_TYPES = {
  RECOMMENDED: 'recommended',
  DISCOUNTED: 'discounted',
  NEW: 'new'
};

export default function RongveProductManager({ setActive }) {
  const [products, setProducts] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    imageUrl: '',
    productUrl: '',
    price: '',
    originalPrice: '',
    productType: PRODUCT_TYPES.RECOMMENDED,
    targetGroups: [],
    targetAll: false,
    targetClubs: []
  });
  const [success, setSuccess] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchProducts();
    getClubInfo();
    getUsers();
  }, []);

  const getClubInfo = async () => {
    try {
      const logoRef = storageRongve.ref("clubLogos/");
      const newClubs = [];
      const res = await logoRef.listAll();
      await Promise.all(
        res.items.map(async (itemRef) => {
          newClubs.push(itemRef.name);
        })
      );

      newClubs.forEach((club, index) => {
        newClubs[index] = club.slice(0, -4);
      });

      setClubs(newClubs);
    } catch (error) {
      console.error("Error fetching clubs:", error);
      alert('Kunne ikke hente klubber');
    }
  };

  const fetchProducts = async () => {
    try {
      console.log('Fetching products...');
      const productsData = await productApi.getAllProducts();
      console.log('Fetched products:', productsData);
      
      if (Array.isArray(productsData)) {
        // Sorter produktene etter opprettelsesdato (nyeste først)
        const sortedProducts = productsData.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
        setProducts(sortedProducts);
      } else {
        console.error('Products data is not an array:', productsData);
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      console.error("Error details:", error.message);
      setProducts([]);
    }
  };

  const handleSubmit = async (e, sendNotification) => {
    e.preventDefault();
    
    try {
      const productData = {
        ...formData,
        price: Number(formData.price),
        originalPrice: formData.originalPrice ? Number(formData.originalPrice) : null,
        createdAt: new Date().toISOString(),
        active: true
      };

      if (editingProduct) {
        await productApi.updateProduct(editingProduct.id, productData);
        setSuccess('Produktet ble oppdatert!');
      } else {
        await productApi.addProduct(productData);
        setSuccess('Produktet ble lagt til!');
        
        if (sendNotification) {
          sendProductNotification(productData);
        }
      }

      await fetchProducts();
      resetForm();
    } catch (error) {
      console.error("Error handling product:", error);
      alert('Det oppstod en feil');
    }
  };

  const sendProductNotification = async (product) => {
    let pushTokens = [];
    let title = '';
    
    switch (product.productType) {
        case PRODUCT_TYPES.RECOMMENDED:
            title = 'Her er et nytt anbefalt produkt for deg!';
            break;
        case PRODUCT_TYPES.DISCOUNTED:
            title = 'Her er et rabattert produkt for deg!';
            break;
        case PRODUCT_TYPES.NEW:
            title = 'Sjekk ut dette nye produktet!';
            break;
        default:
            title = 'Nytt produkt tilgjengelig!';
    }

    users.forEach((user) => {
        if (user.expoPushToken && user.expoPushToken.length > 0) {
            const userClubIds = (user.clubIds || []).map((id) => id.replace(".png", ""));
            
            const isForEveryone = product.targetAll;
            const relevantClubs = userClubIds.filter((club) => product.targetClubs.includes(club));
            const hasClub = relevantClubs.length > 0;
            
            const isOnlyRolesNews = 
                product.targetGroups.includes(user.userType) && 
                !product.targetClubs.length;
                
            const isGeneralRoleNews = 
                product.targetGroups.includes(user.userType) && 
                product.targetGroups.length === 1;
                
            const isRoleAndClubNews = 
                hasClub && product.targetGroups.includes(user.userType);
                
            const isGeneralClubNews = 
                hasClub && product.targetClubs.length === 1 && 
                !product.targetGroups.length;
                
            const isTrainerNews = 
                user.trainer && 
                product.targetGroups.includes('trener') && 
                (hasClub || product.targetClubs.length === 1);

            if (
                isForEveryone ||
                isGeneralRoleNews ||
                isRoleAndClubNews ||
                isOnlyRolesNews ||
                isGeneralClubNews ||
                isTrainerNews
            ) {
                pushTokens.push(user.expoPushToken);
            }
        }
    });

    pushTokens = new Set(pushTokens);
    pushTokens = JSON.stringify(Array.from(pushTokens));

    await Axios.post(
        `https://webnow-adminpanel.ew.r.appspot.com/send?pushTokens=${pushTokens}&title=${title}&body=${product.title}&useFcmV1=true`
    );
  };

  const handleDelete = async (productId) => {
    if (!window.confirm('Er du sikker på at du vil slette dette produktet?')) {
      return;
    }

    try {
      await productApi.deleteProduct(productId);
      await fetchProducts();
      setSuccess('Produktet ble slettet!');
    } catch (error) {
      console.error("Error deleting product:", error);
      alert('Kunne ikke slette produktet');
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      imageUrl: '',
      productUrl: '',
      price: '',
      originalPrice: '',
      productType: PRODUCT_TYPES.RECOMMENDED,
      targetGroups: [],
      targetAll: false,
      targetClubs: []
    });
    setEditingProduct(null);
    setShowAddForm(false);
  };

  const getUsers = async () => {
    try {
      const usersRef = firestoreRongve.collection("users");
      const snapshot = await usersRef.get();
      const userList = [];
      snapshot.forEach((doc) => {
        const userData = doc.data();
        userList.push({ id: doc.id, ...userData });
      });
      setUsers(userList);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div style={productStyles.container}>
      <div style={productStyles.topActions}>
        <button 
          onClick={() => setShowAddForm(true)}
          style={productStyles.addButton}
        >
          <FaPlus /> Legg til nytt produkt
        </button>

        <button
          onClick={() => setActive("")}
          style={productStyles.backButton}
        >
          <FaArrowLeft /> Tilbake
        </button>
      </div>

      <h2 style={productStyles.header}>Produktadministrasjon</h2>
      
      {/* Produktlister kategorisert etter type */}
      <div style={productStyles.productCategories}>
        {/* Anbefalte produkter */}
        <div style={productStyles.productCategory}>
          <h3><FaStar /> Anbefalte produkter</h3>
          <div style={productStyles.productGrid}>
            {products
              .filter(p => p.productType === PRODUCT_TYPES.RECOMMENDED)
              .map(product => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onEdit={() => {
                    setEditingProduct(product);
                    setFormData({...product});
                    setShowAddForm(true);
                  }}
                  onDelete={() => handleDelete(product.id)}
                />
              ))}
          </div>
        </div>

        {/* Rabatterte produkter */}
        <div style={productStyles.productCategory}>
          <h3><FaTags /> Rabatterte produkter</h3>
          <div style={productStyles.productGrid}>
            {products
              .filter(p => p.productType === PRODUCT_TYPES.DISCOUNTED)
              .map(product => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onEdit={() => {
                    setEditingProduct(product);
                    setFormData({...product});
                    setShowAddForm(true);
                  }}
                  onDelete={() => handleDelete(product.id)}
                />
              ))}
          </div>
        </div>

        {/* Nye produkter */}
        <div style={productStyles.productCategory}>
          <h3><FaNewspaper /> Nye produkter</h3>
          <div style={productStyles.productGrid}>
            {products
              .filter(p => p.productType === PRODUCT_TYPES.NEW)
              .map(product => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onEdit={() => {
                    setEditingProduct(product);
                    setFormData({...product});
                    setShowAddForm(true);
                  }}
                  onDelete={() => handleDelete(product.id)}
                />
              ))}
          </div>
        </div>
      </div>

      {/* Modal/Form for å legge til/redigere produkt */}
      {showAddForm && (
        <ProductForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleSubmit}
          onCancel={resetForm}
          clubs={clubs}
          isEditing={!!editingProduct}
        />
      )}

      {success && (
        <div style={productStyles.alert}>
          {success}
        </div>
      )}
    </div>
  );
} 