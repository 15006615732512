import React from 'react';
import { FaEdit, FaTrash, FaCalendar } from 'react-icons/fa';
import { productStyles } from './ProductStyles';

export default function ProductCard({ product, onEdit, onDelete }) {
  // Formater datoen
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('no-NO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <div style={productStyles.card}>
      <img 
        src={product.imageUrl} 
        alt={product.title} 
        style={productStyles.image}
      />
      <div style={productStyles.content}>
        <h4 style={productStyles.title}>{product.title}</h4>
        <div style={productStyles.priceContainer}>
          {product.originalPrice ? (
            <>
              <span style={productStyles.originalPrice}>{product.originalPrice} kr</span>
              <span style={productStyles.price}>{product.price} kr</span>
            </>
          ) : (
            <span style={productStyles.price}>{product.price} kr</span>
          )}
        </div>
        <div style={productStyles.targetInfo}>
          {product.targetAll ? (
            <span>Tilgjengelig for alle</span>
          ) : (
            <span>{product.targetClubs.length} målgrupper</span>
          )}
        </div>
        <div style={productStyles.dateInfo}>
          <FaCalendar style={productStyles.dateIcon} />
          <span>{formatDate(product.createdAt)}</span>
        </div>
      </div>
      <div style={productStyles.actions}>
        <button 
          onClick={onEdit}
          style={productStyles.editButton}
          title="Rediger"
        >
          <FaEdit />
        </button>
        <button 
          onClick={onDelete}
          style={productStyles.deleteButton}
          title="Slett"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
} 