export const styles = {
    container: {
      maxWidth: '1000px',
      margin: '20px auto',
      padding: '30px',
      backgroundColor: '#1E1E1E',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      color: '#FFFFFF',
    },
    header: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '25px',
      color: '#4A90E2',
      display: 'flex',
      alignItems: 'center',
    },
    headerIcon: {
      marginRight: '10px',
      fontSize: '24px',
    },
    inputGroup: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    inputIcon: {
      marginRight: '10px',
      fontSize: '20px',
      color: '#4A90E2',
    },
    input: {
      flex: 1,
      padding: '12px',
      backgroundColor: '#2C2C2C',
      border: '1px solid #3A3A3A',
      borderRadius: '6px',
      color: '#FFFFFF',
      fontSize: '16px',
    },
    fileInput: {
      flex: 1,
      padding: '12px',
      backgroundColor: '#2C2C2C',
      border: '1px solid #3A3A3A',
      borderRadius: '6px',
      color: '#FFFFFF',
      fontSize: '16px',
      cursor: 'pointer',
    },
    quillContainer: {
      marginBottom: '20px',
      backgroundColor: '#fff',
      color: "#000",
      border: '5px solid #3A3A3A',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      borderRadius: "20px",
    },
    quillModules: {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    },
    categorySection: {
      marginBottom: '30px',
      backgroundColor: '#2C2C2C',
      borderRadius: '8px',
      padding: '20px',
    },
    subCategoryHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '15px',
      color: '#4A90E2',
    },
    selectionContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '12px',
    },
    selectionBox: {
      backgroundColor: '#3A3A3A',
      border: '1px solid #4A4A4A',
      borderRadius: '6px',
      padding: '10px 15px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      fontSize: '14px',
      color: '#FFFFFF',
      borderWidth: '3px',
      borderColor: '#525151',
      '&:hover': {
        backgroundColor: '#454545',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      },
    },
    selectedBox: {
      backgroundColor: '#4A90E2',
      borderColor: '#fff',
      color: '#FFFFFF',
    },
    categoryHeader: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '15px',
      color: '#4A90E2',
      display: 'flex',
      alignItems: 'center',
    },
    categoryIcon: {
      marginRight: '10px',
      fontSize: '18px',
    },
    notificationLabel: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      cursor: 'pointer',
    },
    notificationContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    notificationIcon: {
      marginRight: '10px',
      fontSize: '20px',
      color: '#4A90E2',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    backButton: {
      padding: '12px 20px',
      backgroundColor: '#2C2C2C',
      border: 'none',
      borderRadius: '6px',
      color: '#FFFFFF',
      fontSize: '16px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#3A3A3A',
      },
    },
    submitButton: {
      padding: '12px 20px',
      backgroundColor: '#4A90E2',
      border: 'none',
      borderRadius: '6px',
      color: '#FFFFFF',
      fontSize: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#3A78C2',
      },
    },
    buttonIcon: {
      marginRight: '8px',
    },
    alert: {
        backgroundColor: '#d4edda',
        color: '#155724',
        padding: '10px',
        borderRadius: '4px',
        marginTop: '10px',
      },
    productList: {
      marginBottom: '20px',
    },
    productListItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '15px',
      backgroundColor: '#2C2C2C',
      borderRadius: '8px',
      marginBottom: '10px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#3A3A3A',
      },
    },
    selectedProduct: {
      backgroundColor: '#4A90E2',
      '&:hover': {
        backgroundColor: '#3A78C2',
      },
    },
    productListImage: {
      width: '60px',
      height: '60px',
      objectFit: 'cover',
      borderRadius: '4px',
      marginRight: '15px',
    },
    productListInfo: {
      flex: 1,
    },
    productCategories: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      marginTop: '20px',
    },
    productCategory: {
      backgroundColor: '#f8f9fa',
      padding: '20px',
      borderRadius: '8px',
    },
    productGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '20px',
      marginTop: '15px',
    },
    addButton: {
      backgroundColor: '#2ecc71',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '10px 20px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: '16px',
    },
  };