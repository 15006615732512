import React, { useEffect, useState } from "react";
import Axios from "axios";
import ActionButtons from "../../components/ActionButtons";
import { storageRongve, firestoreRongve } from "../../firebase";
import { FaBell, FaUsers, FaHeading, FaFileAlt } from 'react-icons/fa';
import { styles } from './Styles';

const otherCategories = ["Trener", "Forelder", "Spiller"];

export default function RongvePushNotifications(props) {
  const [users, setUsers] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const getUsers = async () => {
    const usersRef = firestoreRongve.collection("users");
    const snapshot = await usersRef.get();
    const userList = [];
    snapshot.forEach((doc) => {
      const userData = doc.data();
      userList.push({ id: doc.id, ...userData });
    });
    setUsers(userList);
  };

  const getClubInfo = async () => {
    const logoRef = storageRongve.ref("clubLogos/");
    const newClubs = [];
    const res = await logoRef.listAll();
    await Promise.all(
      res.items.map(async (itemRef) => {
        newClubs.push(itemRef.name);
      })
    );

    newClubs.forEach((club, index) => {
      newClubs[index] = club.slice(0, -4);
    });

    setClubs(newClubs);
  };
  const isSelected = (item) => selectedClubs.includes(item) || (selectAll && item !== "Alle");

  const handleClubSelection = (club) => {
    if (selectedClubs.includes(club)) {
      setSelectedClubs(selectedClubs.filter((id) => id !== club));
    } else {
      setSelectedClubs([...selectedClubs, club]);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClubs([...clubs, ...otherCategories]);
      setSelectAll(true);
      setSelectedClubs((prev) => [...prev, "Alle"]);
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#selectAll)'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      setSelectedClubs([]);
      setSelectAll(false);
    }
  };

  const handlePushNotification = () => {
    let pushTokens = [];
    const title = document.getElementById("title").value;
    const body = document.getElementById("body").value;

    users.forEach((user) => {
      if (user.expoPushToken && user.expoPushToken.length > 0) {
        const userClubIds = (user.clubIds || []).map((id) =>
          id.replace(".png", "")
        );

        const isForEveryone = selectedClubs.includes("Alle");

        const relevantClubs = userClubIds.filter((club) =>
          selectedClubs.includes(club)
        );
        const hasClub = relevantClubs.length > 0;
        const isOnlyRolesNews =
          selectedClubs.includes(user.userType) &&
          !selectedClubs.some((c) => clubs.includes(c));
        const isGeneralRoleNews =
          selectedClubs.includes(user.userType) && selectedClubs.length === 1;
        const isRoleAndClubNews =
          hasClub && selectedClubs.includes(user.userType);
        const isGeneralClubNews = hasClub && selectedClubs.length === 1;
        const isTrainerNews =
          user.trainer &&
          selectedClubs.includes("Trener") &&
          (hasClub || selectedClubs.length === 1);

        if (
          isForEveryone ||
          isGeneralRoleNews ||
          isRoleAndClubNews ||
          isOnlyRolesNews ||
          isGeneralClubNews ||
          isTrainerNews
        ) {
          pushTokens.push(user.expoPushToken);
        }
      }
    });

    pushTokens = new Set(pushTokens);
    pushTokens = JSON.stringify(Array.from(pushTokens));

    if (title && body && selectedClubs.length > 0) {
      setAlerts([...alerts, { title }]);

      Axios.post(
        `https://webnow-adminpanel.ew.r.appspot.com/send?pushTokens=${pushTokens}&title=${title}&body=${body}&useFcmV1=true`
      ).then((response) => console.log(response.data));
    }
  };

  useEffect(() => {
    getUsers();
    getClubInfo();
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>
        <FaBell style={styles.headerIcon} /> Send push notifikasjon
      </h2>
      <form>
        <div style={styles.inputGroup}>
          <FaHeading style={styles.inputIcon} />
          <input
            type="text"
            style={styles.input}
            id="title"
            placeholder="Tittel"
          />
        </div>
        
        <div style={styles.inputGroup}>
          <FaFileAlt style={styles.inputIcon} />
          <input
            type="text"
            style={styles.input}
            id="body"
            placeholder="Innhold"
          />
        </div>
        
        <div style={styles.categorySection}>
          <h3 style={styles.categoryHeader}>
            <FaUsers style={styles.categoryIcon} /> Velg målgrupper
          </h3>
          <div style={styles.selectionContainer}>
            <div 
              style={{
                ...styles.selectionBox,
                ...(selectAll ? styles.selectedBox : {})
              }}
              onClick={handleSelectAll}
            >
              Alle
            </div>
          </div>
          
          <h4 style={styles.subCategoryHeader}>Velg kategorier:</h4>
          <div style={styles.selectionContainer}>
            {otherCategories.map((category) => (
              <div 
                key={category}
                style={{
                  ...styles.selectionBox,
                  ...(isSelected(category) ? styles.selectedBox : {})
                }}
                onClick={() => handleClubSelection(category)}
              >
                {category}
              </div>
            ))}
          </div>
          
          <h4 style={styles.subCategoryHeader}>Velg klubber:</h4>
          <div style={styles.selectionContainer}>
            {clubs.map((club) => (
              <div 
                key={club}
                style={{
                  ...styles.selectionBox,
                  ...(isSelected(club) ? styles.selectedBox : {})
                }}
                onClick={() => handleClubSelection(club)}
              >
                {club}
              </div>
            ))}
          </div>
        </div>
        
        <ActionButtons
          setActive={props.setActive}
          onClick={handlePushNotification}
        />
      </form>
      
      {alerts.length > 0 &&
        alerts.map((alert, index) => (
          <div key={index} style={styles.alert}>
            Push notifikasjon med tittel <b>{alert.title}</b> sendt ut. Kan ta
            opptil 20 minutter før den vises.
          </div>
        ))}
    </div>
  );
}