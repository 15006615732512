import React, { useState } from 'react';
import { FaStar, FaTags, FaNewspaper } from 'react-icons/fa';
import { PRODUCT_TYPES } from '../RongveProductManager';
import { productStyles } from './ProductStyles';

const TARGET_GROUPS = {
  FORELDER: 'forelder',
  SPILLER: 'spiller',
  TRENER: 'trener'
};

export default function ProductForm({ 
  formData, 
  setFormData, 
  onSubmit, 
  onCancel, 
  clubs,
  isEditing 
}) {
  const [sendNotification, setSendNotification] = useState(false);

  const isSelected = (item) => 
    formData.targetClubs.includes(item) || 
    (formData.targetAll && item !== "Alle");

  const handleClubSelection = (club) => {
    if (formData.targetClubs.includes(club)) {
      setFormData({
        ...formData,
        targetClubs: formData.targetClubs.filter(id => id !== club)
      });
    } else {
      setFormData({
        ...formData,
        targetClubs: [...formData.targetClubs, club]
      });
    }
  };

  const handleSelectAll = () => {
    if (!formData.targetAll) {
      setFormData({
        ...formData,
        targetAll: true,
        targetClubs: [...clubs, "Alle"]
      });
    } else {
      setFormData({
        ...formData,
        targetAll: false,
        targetClubs: []
      });
    }
  };

  const calculateDiscount = () => {
    if (formData.originalPrice && formData.price) {
      const discount = ((formData.originalPrice - formData.price) / formData.originalPrice) * 100;
      return Math.round(discount);
    }
    return null;
  };

  const handleTargetGroupSelection = (group) => {
    setFormData(prevData => {
      const currentGroups = prevData.targetGroups || [];
      if (currentGroups.includes(group)) {
        return {
          ...prevData,
          targetGroups: currentGroups.filter(g => g !== group)
        };
      } else {
        return {
          ...prevData,
          targetGroups: [...currentGroups, group]
        };
      }
    });
  };

  return (
    <div style={productStyles.modalOverlay}>
      <div style={productStyles.modalContent}>
        <h3 style={productStyles.modalHeader}>
          {isEditing ? 'Rediger produkt' : 'Legg til nytt produkt'}
        </h3>

        <form onSubmit={(e) => onSubmit(e, sendNotification)}>
          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Produkttype</h4>
            <div style={productStyles.typeSelector}>
              <button
                type="button"
                style={{
                  ...productStyles.typeButton,
                  ...(formData.productType === PRODUCT_TYPES.RECOMMENDED ? productStyles.selectedType : {})
                }}
                onClick={() => setFormData({...formData, productType: PRODUCT_TYPES.RECOMMENDED})}
              >
                <FaStar /> Anbefalt
              </button>
              <button
                type="button"
                style={{
                  ...productStyles.typeButton,
                  ...(formData.productType === PRODUCT_TYPES.DISCOUNTED ? productStyles.selectedType : {})
                }}
                onClick={() => setFormData({...formData, productType: PRODUCT_TYPES.DISCOUNTED})}
              >
                <FaTags /> Rabattert
              </button>
              <button
                type="button"
                style={{
                  ...productStyles.typeButton,
                  ...(formData.productType === PRODUCT_TYPES.NEW ? productStyles.selectedType : {})
                }}
                onClick={() => setFormData({...formData, productType: PRODUCT_TYPES.NEW})}
              >
                <FaNewspaper /> Nyhet
              </button>
            </div>
          </div>

          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Produktinformasjon</h4>
            <div style={productStyles.inputGroup}>
              <label style={productStyles.label}>Produkttittel</label>
              <input
                style={productStyles.input}
                type="text"
                placeholder="F.eks: Adidas Copa Pure 2 League"
                value={formData.title}
                onChange={(e) => setFormData({...formData, title: e.target.value})}
                required
              />
            </div>

            <div style={productStyles.inputGroup}>
              <label style={productStyles.label}>Produkt URL</label>
              <input
                style={productStyles.input}
                type="url"
                placeholder="https://rongveklubb.no/product/..."
                value={formData.productUrl}
                onChange={(e) => setFormData({...formData, productUrl: e.target.value})}
                required
              />
            </div>

            <div style={productStyles.inputGroup}>
              <label style={productStyles.label}>Bilde URL</label>
              <input
                style={productStyles.input}
                type="url"
                placeholder="https://example.com/image.jpg"
                value={formData.imageUrl}
                onChange={(e) => setFormData({...formData, imageUrl: e.target.value})}
                required
              />
            </div>
          </div>

          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Prisinformasjon</h4>
            <div style={productStyles.priceGroup}>
              <div style={productStyles.inputGroup}>
                <label style={productStyles.label}>Pris</label>
                <input
                  style={productStyles.input}
                  type="number"
                  placeholder="899"
                  value={formData.price}
                  onChange={(e) => setFormData({...formData, price: e.target.value})}
                  required
                />
              </div>
              
              {formData.productType === PRODUCT_TYPES.DISCOUNTED && (
                <div style={productStyles.inputGroup}>
                  <label style={productStyles.label}>Opprinnelig pris</label>
                  <input
                    style={productStyles.input}
                    type="number"
                    placeholder="1299"
                    value={formData.originalPrice}
                    onChange={(e) => setFormData({...formData, originalPrice: e.target.value})}
                    required
                  />
                </div>
              )}
            </div>
            {calculateDiscount() && (
              <div style={productStyles.discountBadge}>
                {calculateDiscount()}% rabatt
              </div>
            )}
          </div>

          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Målgrupper</h4>
            <div style={productStyles.targetGroupSelector}>
              <button
                type="button"
                style={{
                  ...productStyles.targetGroupButton,
                  ...(formData.targetGroups?.includes(TARGET_GROUPS.SPILLER) ? productStyles.selectedTargetGroup : {})
                }}
                onClick={() => handleTargetGroupSelection(TARGET_GROUPS.SPILLER)}
              >
                Spiller
              </button>
              <button
                type="button"
                style={{
                  ...productStyles.targetGroupButton,
                  ...(formData.targetGroups?.includes(TARGET_GROUPS.FORELDER) ? productStyles.selectedTargetGroup : {})
                }}
                onClick={() => handleTargetGroupSelection(TARGET_GROUPS.FORELDER)}
              >
                Forelder
              </button>
              <button
                type="button"
                style={{
                  ...productStyles.targetGroupButton,
                  ...(formData.targetGroups?.includes(TARGET_GROUPS.TRENER) ? productStyles.selectedTargetGroup : {})
                }}
                onClick={() => handleTargetGroupSelection(TARGET_GROUPS.TRENER)}
              >
                Trener
              </button>
            </div>
          </div>

          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Velg klubber</h4>
            <div style={productStyles.clubSelector}>
              <div 
                style={{
                  ...productStyles.selectionBox,
                  ...(formData.targetAll ? productStyles.selectedBox : {})
                }}
                onClick={handleSelectAll}
              >
                Alle brukere
              </div>
              {!formData.targetAll && (
                <div style={productStyles.clubGrid}>
                  {clubs.map((club) => (
                    <div 
                      key={club}
                      style={{
                        ...productStyles.selectionBox,
                        ...(isSelected(club) ? productStyles.selectedBox : {})
                      }}
                      onClick={() => handleClubSelection(club)}
                    >
                      {club}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div style={productStyles.formSection}>
            <h4 style={productStyles.sectionHeader}>Notifikasjoner</h4>
            <div 
              style={{
                ...productStyles.notificationToggle,
                ...(sendNotification ? productStyles.notificationToggleSelected : {})
              }}
              onClick={() => setSendNotification(!sendNotification)}
            >
              <input
                type="checkbox"
                checked={sendNotification}
                onChange={() => setSendNotification(!sendNotification)}
                style={productStyles.checkbox}
              />
              <span style={productStyles.notificationLabel}>
                Send push-notifikasjon til valgte målgrupper
              </span>
            </div>
          </div>

          <div style={productStyles.buttonContainer}>
            <button
              type="button"
              onClick={onCancel}
              style={productStyles.cancelButton}
            >
              Avbryt
            </button>
            <button
              type="submit"
              style={productStyles.submitButton}
            >
              {isEditing ? 'Oppdater produkt' : 'Legg til produkt'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 