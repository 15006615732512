export const productStyles = {
  // Container styles
  container: {
    padding: '20px',
    backgroundColor: '#1E1E1E',
    borderRadius: '8px',
    maxWidth: '1200px',
    margin: '0 auto',
    color: '#FFFFFF',
  },
  header: {
    fontSize: '24px',
    color: '#4A90E2',
    marginBottom: '20px',
  },

  // Product grid styles
  productCategories: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '20px',
  },
  productCategory: {
    backgroundColor: '#2C2C2C',
    padding: '20px',
    borderRadius: '8px',
  },
  productGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    marginTop: '15px',
  },

  // Card styles
  card: {
    position: 'relative',
    backgroundColor: '#3A3A3A',
    borderRadius: '8px',
    padding: '15px',
    color: '#FFFFFF',
  },
  image: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  title: {
    fontSize: '16px',
    marginBottom: '10px',
    color: '#FFFFFF',
  },
  price: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#4A90E2',
  },
  originalPrice: {
    textDecoration: 'line-through',
    color: '#888',
    fontSize: '14px',
  },

  // Form styles
  modalContent: {
    backgroundColor: '#2C2C2C',
    borderRadius: '12px',
    padding: '40px',
    width: '100%',
    maxWidth: '800px',
    position: 'relative',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
  },

  input: {
    width: '100%',
    padding: '12px 16px',
    backgroundColor: '#3A3A3A',
    border: '1px solid #4A4A4A',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '14px',
    transition: 'all 0.2s',
    '&:focus': {
      borderColor: '#4A90E2',
      outline: 'none',
      backgroundColor: '#404040',
    },
  },

  selectionBox: {
    padding: '8px 15px',
    border: '1px solid #4A4A4A',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#3A3A3A',
    color: '#FFFFFF',
  },
  selectedBox: {
    backgroundColor: '#4A90E2',
    border: '1px solid #357ABD',
  },

  // Button styles
  addButton: {
    backgroundColor: '#2ecc71',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  editButton: {
    backgroundColor: '#4A90E2',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#357ABD',
    },
  },
  deleteButton: {
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#c0392b',
    },
  },
  alert: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#2ecc71',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    zIndex: 1000,
  },

  // Legg til disse nye stilene i productStyles
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowY: 'auto',
    padding: '40px 20px',
    zIndex: 1000,
  },

  modalHeader: {
    fontSize: '24px',
    color: '#FFFFFF',
    marginBottom: '30px',
    textAlign: 'center',
  },

  formSection: {
    marginBottom: '40px',
  },

  sectionHeader: {
    fontSize: '18px',
    color: '#4A90E2',
    marginBottom: '20px',
    fontWeight: '500',
  },

  typeSelector: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },

  typeButton: {
    flex: 1,
    padding: '12px',
    backgroundColor: '#3A3A3A',
    border: '1px solid #4A4A4A',
    borderRadius: '6px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    transition: 'all 0.2s',
  },

  selectedType: {
    backgroundColor: '#4A90E2',
    borderColor: '#357ABD',
  },

  priceGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    alignItems: 'center',
  },

  discountBadge: {
    backgroundColor: '#2ecc71',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    display: 'inline-block',
    marginLeft: '10px',
  },

  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#FFFFFF',
    fontSize: '14px',
  },

  targetGroupSelector: {
    display: 'flex',
    gap: '12px',
    marginBottom: '20px',
  },

  targetGroupButton: {
    flex: '1',
    padding: '12px',
    backgroundColor: '#3A3A3A',
    border: '1px solid #4A4A4A',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#404040',
    },
  },

  selectedTargetGroup: {
    backgroundColor: '#4A90E2',
    borderColor: '#357ABD',
    '&:hover': {
      backgroundColor: '#357ABD',
    },
  },

  clubGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gap: '12px',
    marginTop: '20px',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '40px',
  },

  cancelButton: {
    padding: '12px 24px',
    backgroundColor: '#4A4A4A',
    border: 'none',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#5A5A5A',
    },
  },

  submitButton: {
    padding: '12px 24px',
    backgroundColor: '#4A90E2',
    border: 'none',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#357ABD',
    },
  },

  topActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },

  backButton: {
    backgroundColor: '#4A4A4A',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#5A5A5A',
    },
  },

  actions: {
    display: 'flex',
    gap: '12px',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },

  dateInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontSize: '12px',
    color: '#888',
    marginTop: '8px',
  },

  dateIcon: {
    fontSize: '12px',
  },

  content: {
    padding: '15px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  notificationToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '12px 16px',
    backgroundColor: '#3A3A3A',
    border: '1px solid #4A4A4A',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s',
    marginBottom: '20px',
  },

  notificationToggleSelected: {
    backgroundColor: '#2ecc71',
    borderColor: '#27ae60',
  },

  checkbox: {
    width: '20px',
    height: '20px',
    accentColor: '#2ecc71',
  },

  notificationLabel: {
    color: '#FFFFFF',
    fontSize: '14px',
    userSelect: 'none',
  },
}; 