import { firestoreRongve as firestore } from '../firebase';

const productApi = {
  addProduct: async (productData) => {
    try {
      const finalData = {
        ...productData,
        price: Number(productData.price),
        originalPrice: productData.originalPrice ? Number(productData.originalPrice) : null,
        createdAt: new Date().toISOString(),
        active: true,
        targetGroups: productData.targetGroups || [],
        targetClubs: productData.targetClubs || [],
        targetAll: productData.targetAll || false
      };

      if (productData.productType === 'discounted' && productData.originalPrice) {
        const discount = ((productData.originalPrice - productData.price) / productData.originalPrice) * 100;
        finalData.discountPercentage = Math.round(discount);
      }

      const docRef = await firestore.collection('products').add(finalData);
      return docRef.id;
    } catch (error) {
      console.error('Error in addProduct:', error);
      throw error;
    }
  },

  updateProduct: async (productId, productData) => {
    try {
      await firestore.collection('products').doc(productId).update(productData);
      return true;
    } catch (error) {
      console.error('Error updating product:', error);
      throw error;
    }
  },

  deleteProduct: async (productId) => {
    try {
      await firestore.collection('products').doc(productId).delete();
      return true;
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error;
    }
  },

  getAllProducts: async () => {
    try {
      console.log('Starting product fetch...');
      
      const snapshot = await firestore
        .collection('products')
        .where('active', '==', true)
        .get();
      
      console.log('Snapshot exists:', !snapshot.empty);
      
      if (snapshot.empty) {
        console.log('No products found');
        return [];
      }

      const products = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title || '',
          imageUrl: data.imageUrl || '',
          productUrl: data.productUrl || '',
          price: typeof data.price === 'number' ? data.price : Number(data.price),
          originalPrice: data.originalPrice ? Number(data.originalPrice) : null,
          productType: data.productType || 'recommended',
          targetGroups: data.targetGroups || [],
          targetAll: data.targetAll || false,
          targetClubs: data.targetClubs || [],
          discountPercentage: data.discountPercentage || null,
          createdAt: data.createdAt || new Date().toISOString(),
          active: data.active
        };
      });

      console.log('Processed products:', products);
      return products;
    } catch (error) {
      console.error('Error in getAllProducts:', error);
      throw error;
    }
  },

  getProduct: async (productId) => {
    try {
      const doc = await firestore.collection('products').doc(productId).get();
      if (doc.exists) {
        return { id: doc.id, ...doc.data() };
      }
      return null;
    } catch (error) {
      console.error('Error getting product:', error);
      throw error;
    }
  }
};

export default productApi; 